<template>
  <div>
    <tripBookings></tripBookings>
    <!-- <v-card-title class="primary--text">Flight Bookings</v-card-title>
    <v-row>
      <v-col v-for="item in flightBookings" :key="item.id" cols="12" md="6">
        <v-card class="pa-3">
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">From:</span>{{ item.departureLocation }}
          </div>
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">To:</span>{{ item.arrivalLocation }}
          </div>
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">Flight Type:</span>{{ item.flight_type }}
          </div>
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">Price:</span>{{ item.total_price }}&nbsp;{{item.price_currency}}
          </div>
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">Departure date:</span>{{item.departureDate}}
          </div>
          <div class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">Arrival date:</span>{{item.arrivalDate}}
          </div>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'
import tripBookings from '@/components/home_sections/bookings.vue'

export default {
  components: {
    tripBookings
  },
  data () {
    return {
      flightBookings: null
    }
  },
  methods: {},
  created () {
    user.bookings().then(res => {
      if (res.status === 200) {
        this.flightBookings = res.data.data
      }
    })
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
<style scoped>
</style>
